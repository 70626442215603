import axios from './axios';

const _formatError = (error) => {
  const newError = {}
  newError.url = window.location.href
  if ( error ) {
    if ( error.name ) newError.name = error.name
    if ( error.stack ) newError.stack = error.stack.slice(0, 1000)
  }
  return newError
}

export async function alertErrorOnline({ message, error, ...optionalParams }) {
  // console.log('errorDetails', errorDetails)
  // console.log('errorDetails string', errorDetails.toString())
  // console.log('errorDetails stringify', JSON.stringify(errorDetails))
  // console.log('errorDetails.name', errorDetails.name)
  // console.log('errorDetails.line', errorDetails.line)
  // console.log('errorDetails.stack', errorDetails.stack)
  console.log('optionalParams', optionalParams)
  const res = await axios().post(`alert/error`, {
    message,
    error: _formatError(error),
    ...optionalParams
  });
  return res.data;
}