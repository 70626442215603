
if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
  self.sessionStorageData = {}
  self.sessionStorage = {
    setItem(key, value) {
      self.sessionStorageData[key] = value
    },
    getItem(key) {
      return self.sessionStorageData[key]
    },
    removeItem(key) {
      delete self.sessionStorageData[key]
    }
  }

} else if (typeof window !== 'undefined') {
  const finalizeWorker = {
    running: false
  }
  window.finalizeWorker = finalizeWorker
}
