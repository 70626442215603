export default {
  transparent: false,
  light: {
    ambientLight: {
      intensity: 0.3,
      color: '#ffffff'
    },
    directionalLight: {
      intensity: 0.6,
      color: '#ffffff'
    }
  },
  abutments: [],
  example: {
    foo: 'bar',
  }
}