const app = {
  name: "Synergy Guide Design",
  strapi_url: process.env.NEXT_PUBLIC_STRAPI_URL,
  stripe_pk: process.env.NEXT_PUBLIC_STRIPE_PK,
  sentry_dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  next_url: process.env.NEXT_PUBLIC_NEXT_URL,
  geometry_url: process.env.NEXT_PUBLIC_GEOMETRY_SERVICE_URL,
  string_a: process.env.NEXT_PUBLIC_STRING_A,
  string_b: process.env.NEXT_PUBLIC_STRING_B,
}

export default app