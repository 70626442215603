import { createTheme } from '@mui/material/styles'
import { Color } from 'three';

const baseTheme = createTheme({
  palette: {
    primary: {
      main: "#1D427C",
    },
    secondary: {
      main: "#0070F3"
    },
    darkRed: {
      main: 'rgb(212, 35, 40)',
      contrastText: 'white',
    },
    background: {
      body: '#E5E5E5',
      canvas: '#4F4E4E',
    },
    darkblue: {
      main: "#1D427C",
    },
    lightBlueButton: {
      main: "#1CAAE9",
      contrastText: 'white',
    },
    lightBlue: {
      main: "#E9F6FE"
    },
    back: {
      main: '#eef3fb'
    },
    redButton: {
      main: '#E80303',
      contrastText: 'white',
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.05)',
          borderRadius: '5px',
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          '&.HeaderDivider': {
            borderBottom: '1px solid #D9D9D9',
            width: '100%',
          }
        },
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          '&.HeaderText': {
            fontSize: '24px',
            fontWeight: 600,
            fontFamily: 'sans-serif',
          },
          '&.ContentHeaderText': {
            fontSize: '24px',
            fontWeight: 700,
            fontFamily: 'IBM Plex Sans',
          },
          '&.SignoutHeaderText': {
            fontFamily: 'IBM Plex Sans',
            fontSize: '55px',
            fontWeight: 500,
            color: '#D42328',
            lineHeight: '82.5px',
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
          fontWeight: 500,
          fontSize: '12px',

          '&.MuiButton-sizeLarge': {
            fontWeight: 700,
            fontSize: '16px',
            paddingRight: 40,
            paddingLeft: 40,
          },
          '&.MuiButton-containedRedButton': {
            boxShadow: '0px 3px 6px  1px rgba(232, 3, 3, 0.4)'
          },
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.GrayInput': {
            '& .MuiFormLabel-root': {
              color: 'black',
              '&.MuiInputLabel-shrink, &.Mui-focused': {
                background: 'white',
                borderRadius: '4px',
                boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1)',
                padding: "0 10px",
              }
            },
            '& .MuiInputBase-root': {
              background: '#E4E4E4',
              borderRadius: '10px',

              '& fieldset': {
                border: 'none',
              },

              '&.Mui-focused': {
                boxShadow: '2px 2px 5px rgba(29, 66, 124, 0.5)'
              },
              '&.Mui-error:not(.Mui-focused)': {
                boxShadow: '1px 1px 2px rgba(255, 0, 0, 0.7)'
              },

              '& .MuiInputAdornment-root': {
                color: '#D42328',
              }
            }
          }
        }
      }
    }
  },
  sidebarWidth: "239px",
  appbarHeight: "86px",
})
export default baseTheme;

export const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: 'dark',
  },
})

// 3d model theme
export const WIREFRAME_COLOR = new Color('black');
export const DENSITY_OBJECT_COLOR = new Color('white');
export const DENSITY_WIREFRAME_COLOR = new Color(0x638889);
export const DENSITY_SELECTED_FACE_COLOR = new Color(0xEBD9B4);