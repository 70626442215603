import NextHead from 'next/head'
import appConfig from '../config/app'

const Head = (props) => {
  return (
    <NextHead>
      <title>{props.title + " - " + appConfig.name}</title>
      <meta name="description" content={props.descrition || appConfig.name + " by Navagation"} />
    </NextHead>
  )
}

export default Head