import React from "react"
import { Alert, AlertTitle, Backdrop, Button, ThemeProvider, Typography } from "@mui/material"
import Head from "./head"
import baseTheme from "theme"

class ErrorBoundary  extends React.Component {
  constructor(props) {
    super(props)
 
    // Define a state variable to track whether is an error or not
    this.state = { hasError: false }
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
 
    return { hasError: true }
  }
  componentDidCatch(error, errorInfo) {
    // console.log('asdasd', error)
    // You can use your own error logging service here
    // console.log({ error, errorInfo })
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      return (
        <ThemeProvider theme={baseTheme}>
          <Backdrop
            sx={{
              color: "secondary.main",
              zIndex: 9999,
              bgcolor: "rgba(0, 0, 0, 0.1)",
            }}
            open={true}
          >
            <Head title="Unknown Error" />
            <Alert severity="error">
              <AlertTitle>Unknown Error</AlertTitle>
              <Typography mb={1}>
                Unable to continue the process, please try again later.
              </Typography>
              <Button
                variant="contained"
                color="redButton"
                onClick={() => this.setState({ hasError: false })}
              >Try Again</Button>
            </Alert>
          </Backdrop>
        </ThemeProvider>
      )
    }
 
    // Return children components in case of no error
 
    return this.props.children
  }
}
 
export default ErrorBoundary