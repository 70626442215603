import { signOut } from "next-auth/react"
import sessionStorage from 'sessionstorage'
import { setLoginAt } from "../actions/user"
import userState from "@/hook_states/user"

let saveLoginAtInterval
let timeoutTimer

const logout = async() => {
  await signOut({ redirect: false })
  sessionStorage.removeItem('jwt')
}

function startTimer() {
  const timeout = 60 * 60 * 1000 // 1 hours
  timeoutTimer = setTimeout(() => {
    logout()
  }, timeout)
}

function resetTimer() {
  clearTimeout(timeoutTimer)
  startTimer()
}

function initializeAutoLogout() {
  document.addEventListener("mousemove", resetTimer, false);
  document.addEventListener("keydown", resetTimer, false);
  document.addEventListener("touchstart", resetTimer, false);

  saveLoginAtInterval = setInterval(async() => {
    await setLoginAt()
  }, 10 * 60 * 1000) // 10 minutes
}

function destroyAutoLogout() {
  clearTimeout(timeoutTimer)
  clearInterval(saveLoginAtInterval)

  document.removeEventListener("mousemove", resetTimer);
  document.removeEventListener("keydown", resetTimer);
  document.removeEventListener("touchstart", resetTimer);
}

const logoutIfHourPassed = async() => {
  const anHourAgo = Date.now() - ( 1000 * 60 * 60 )
  if ( new Date(userState.lastLoginAt.value) < anHourAgo ) {
    await logout()
    return true
  }
  return false
}

export {
  initializeAutoLogout,
  destroyAutoLogout,
  logoutIfHourPassed,
}