import axios from './axios';

export async function signIn({ email, password }) {
  const res = await axios({headers: {}}).post(`auth/local`, {
    identifier: email,
    password,
  });
  return res.data;
}

export async function getSignInData() {
  const res = await axios().get(`users/signin-data`);
  return res.data;
}

export async function setLoginAt(email) {
  const opt = {}
  if ( email ) {
    opt.headers = {}
  }
  const res = await axios(opt).put(`users/loginAt`, {
    email
  });
  return res.data;
}

export async function forgotPassword({ email }) {
  try {
    const res = await axios({ headers: {}}).post(`auth/forgot-password`, {
      email
    })
    return res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      return err.response.data;
    }
    return { error: err };
  }
}

export async function resetPassword({ code, password, passwordConfirmation }) {
  try {
    const res = await axios({ headers: {}}).post(`auth/reset-password`, {
      password,
      passwordConfirmation,
      code
    })
    return res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      return err.response.data;
    }
    return { error: err };
  }
}

export async function acceptInvitation({ code }) {
  try {
    const res = await axios({ headers: {}}).post(`users/accept-invitation`, {
      code
    })
    return res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      return err.response.data;
    }
    return { error: err };
  }
}

export async function checkResetPasswordCode(code) {
  try {
    const res = await axios({ headers: {}}).get(`users/check-code/${code}`)
    return res.data;
  } catch (err) {
    if (err.response && err.response.data) {
      return err.response.data;
    }
    return { error: err };
  }
}