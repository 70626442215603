import axios from 'axios'
import sessionStorage from 'sessionstorage'
import app from '../config/app';
import '../services/worker/global_object'

let sesStore = sessionStorage
try {
  if (typeof WorkerGlobalScope !== 'undefined' && self instanceof WorkerGlobalScope) {
    sesStore = self.sessionStorage
  }
} catch (err) { }

const strapiUrl = app.strapi_url;

export default (opts) => {
  let headers = {}
  if (sesStore.getItem('jwt')) {
    headers.Authorization = `Bearer ${sesStore.getItem('jwt')}`
  }

  return axios.create({
    baseURL: `${strapiUrl}/api/`,
    headers,
    ...opts,
  })
};