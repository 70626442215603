import sessionStorage from 'sessionstorage'
import userState from "../hook_states/user";
import { getSignInData, setLoginAt as setLoginAtServer } from "../services/auth"

export const setSignInData = async () => {
  const sessionUser = sessionStorage.getItem('user') && JSON.parse(sessionStorage.getItem('user'))
  const user = await getSignInData()
  sessionStorage.setItem('user', JSON.stringify(user))
  userState.set(user)
}

export const reloadSignInData = async () => {
  sessionStorage.removeItem('user')
  await setSignInData()
}

export const setLoginAt = async () => {
  await setLoginAtServer()
  userState.lastLoginAt.set(new Date())
}